<div class="cards pb-2" (click)="openDetailCoupon(isClient)">
  <div class="name d-flex align-items-center p-2">
    <div class="avatar">
      <img
        [src]="coupons.logo"
        alt="Logo {{ coupons.local_nombre }}"
        title="Local {{ coupons.local_nombre }}"
      />
    </div>
    <h2 class="ms-3 text-truncate">{{ coupons.local_nombre }}</h2>
    <i class="fas fa-share-alt ms-auto" (click)="openWhats()" *ngIf="shareLink" [style.color]="primaryColor"></i>
  </div>
  <div class="d-flex images" [style.opacity]='showLocked ? 0.5 : 1'>
    <div class="col-6" *ngIf="coupons.cupon_imagen != null">
      <div class="image">
        <img
          [src]="coupons.cupon_imagen"
          alt="Imagen {{ coupons.local_nombre }}"
          title="{{ coupons.local_nombre }}"
        />
      </div>
    </div>
    <div
      class="percentage d-flex align-items-center justify-content-center"
      [ngClass]="coupons.cupon_imagen == null ? 'col-12 image-full' : 'col-6'"
      [style.backgroundColor]="primaryColor"
    >
      <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg" 
        style="position: relative; left: 55%; bottom: 35%;" *ngIf='showLocked && coupons.cupon_imagen == null'>
        <path d="M20.9193 12.0655C20.582 11.7283 20.1728 11.5599 19.6909 11.5599H19.1133V8.09174C19.1133 5.87616 18.3188 3.97378 16.7292 2.38431C15.1398 0.794769 13.2376 0 11.0217 0C8.80588 0 6.90331 0.794769 5.31403 2.38424C3.72449 3.97378 2.92997 5.87609 2.92997 8.09174V11.5599H2.35207C1.87058 11.5599 1.46108 11.7283 1.12389 12.0655C0.786696 12.4025 0.618164 12.812 0.618164 13.2939V23.6976C0.618164 24.1791 0.786759 24.5887 1.12389 24.9259C1.46108 25.2628 1.87058 25.4315 2.35207 25.4315H19.6914C20.1732 25.4315 20.5824 25.263 20.9197 24.9259C21.2566 24.5887 21.4255 24.1791 21.4255 23.6976V13.2937C21.4257 12.8122 21.2566 12.4027 20.9193 12.0655ZM15.6455 11.5599H6.39791V8.09174C6.39791 6.81542 6.84954 5.72559 7.75262 4.82264C8.65582 3.91957 9.74545 3.46812 11.0219 3.46812C12.2985 3.46812 13.3879 3.9195 14.2911 4.82264C15.194 5.72553 15.6455 6.81542 15.6455 8.09174V11.5599Z" fill="white"/>
      </svg>

      <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg" 
        style="position: relative; left: 80%; bottom: 35%;" *ngIf='showLocked && coupons.cupon_imagen != null'>
        <path d="M20.9193 12.0655C20.582 11.7283 20.1728 11.5599 19.6909 11.5599H19.1133V8.09174C19.1133 5.87616 18.3188 3.97378 16.7292 2.38431C15.1398 0.794769 13.2376 0 11.0217 0C8.80588 0 6.90331 0.794769 5.31403 2.38424C3.72449 3.97378 2.92997 5.87609 2.92997 8.09174V11.5599H2.35207C1.87058 11.5599 1.46108 11.7283 1.12389 12.0655C0.786696 12.4025 0.618164 12.812 0.618164 13.2939V23.6976C0.618164 24.1791 0.786759 24.5887 1.12389 24.9259C1.46108 25.2628 1.87058 25.4315 2.35207 25.4315H19.6914C20.1732 25.4315 20.5824 25.263 20.9197 24.9259C21.2566 24.5887 21.4255 24.1791 21.4255 23.6976V13.2937C21.4257 12.8122 21.2566 12.4027 20.9193 12.0655ZM15.6455 11.5599H6.39791V8.09174C6.39791 6.81542 6.84954 5.72559 7.75262 4.82264C8.65582 3.91957 9.74545 3.46812 11.0219 3.46812C12.2985 3.46812 13.3879 3.9195 14.2911 4.82264C15.194 5.72553 15.6455 6.81542 15.6455 8.09174V11.5599Z" fill="white"/>
      </svg>
      
      <span *ngIf="!coupons.encuesta_id" [ngClass]="coupons.nombre.length > 6 ? 'large-title' : ''">{{ coupons.nombre }}</span>
      <span *ngIf="coupons.encuesta_id" style="font-size: 36px;">Encuesta</span>
    </div>
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'canje' && coupons.puntos_valor">
    {{ coupons.puntos_valor }} puntos
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'canje' && !coupons.puntos_valor">
    Programa de puntos
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'bienvenida'">
    Bienvenida
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'cumpleanios'">
    Cumpleaños
  </div>
  <div class="rotulo-programa-de-puntos p-2" *ngIf="coupons.cupon_tipo === 'aniversario'">
    Aniversario
  </div>
  <div class="description p-2" *ngIf="!coupons.encuesta_id">{{ coupons.descripcion }}</div>
  <div
    class="bg p-1 d-flex align-items-center justify-content-between mx-2 mb-2" [style.backgroundColor]="softColor"
  >
    <span class="d-flex days">
      <span [ngClass]="{ selected: coupons.dia_lu }" [style.backgroundColor]="coupons.dia_lu ? primaryColor : ''">L</span>
      <span [ngClass]="{ selected: coupons.dia_ma }" [style.backgroundColor]="coupons.dia_ma ? primaryColor : ''">M</span>
      <span [ngClass]="{ selected: coupons.dia_mi }" [style.backgroundColor]="coupons.dia_mi ? primaryColor : ''">M</span>
      <span [ngClass]="{ selected: coupons.dia_ju }" [style.backgroundColor]="coupons.dia_ju ? primaryColor : ''">J</span>
      <span [ngClass]="{ selected: coupons.dia_vi }" [style.backgroundColor]="coupons.dia_vi ? primaryColor : ''">V</span>
      <span [ngClass]="{ selected: coupons.dia_sa }" [style.backgroundColor]="coupons.dia_sa ? primaryColor : ''">S</span>
      <span [ngClass]="{ selected: coupons.dia_do }" [style.backgroundColor]="coupons.dia_do ? primaryColor : ''">D</span>
    </span>
    <span class="date" [style.color]="primaryColor"
      >{{ "coupon.until" | translate }}
      {{ coupons.fecha_hasta | date: "dd/MM" }}
    </span>
  </div>
  <div class="row mx-2 mb-2">
    <div class="col-6 pe-3 mb-md-0 ps-0 mb-2">
      <div class="bg p-1 location" [style.backgroundColor]="softColor">
        <ng-container *ngIf="coupons.tipo == 'local'; else eshop">
          <ng-container *ngIf="activated; else neighbour">
          <i class="fas fa-map-marker-alt me-1" *ngIf="secondaryColor; else defaultMapIcon" style="color:#828282;"></i>
          <ng-template #defaultMapIcon>
            <i class="fas fa-map-marker-alt me-1"></i>
          </ng-template>
          {{ coupons.distancia ? (coupons.distancia | fixed: "2") + "km" : "" }}
          {{ coupons.barrio }}
          </ng-container>
          <ng-template #neighbour>
            {{ coupons.barrio }}
          </ng-template>
        </ng-container>
        <ng-template #eshop>
          {{ coupons.categoria_nombre }}
        </ng-template>
      </div>
    </div>
    <div class="col-6 px-0" *ngIf="withButtons && !coupons.encuesta_id">
      <button class="landingButton" mat-raised-button *ngIf="secondaryColor; else defaultButton" [style.backgroundColor]="secondaryColor">
        {{ "coupon.activate_coupon" | translate }}
      </button>
      <ng-template #defaultButton>
        <button mat-raised-button color="basic">
          {{ "coupon.activate_coupon" | translate }}
        </button>
      </ng-template>
    </div>
    <div class="col-6 px-0" *ngIf="withButtons && coupons.encuesta_id">
      <button class="landingButton" mat-raised-button *ngIf="secondaryColor; else defaultButtonEncuesta" [style.backgroundColor]="secondaryColor">
        Completar Encuesta
      </button>
      <ng-template #defaultButtonEncuesta>
        <button mat-raised-button color="basic">
          Completar Encuesta
        </button>
      </ng-template>
    </div>
  </div>
  <div
    class="whatsapp p-1 d-flex align-items-center mx-2 mb-2 pointer"
    (click)="sendWhatsapp()"
    *ngIf="withButtons"
    [ngClass]="{ chat: coupons.whatsapp }"
    [style.color]="primaryColor"
    [style.backgroundColor]="coupons.whatsapp != null ? softColor : ''"
  >
    <ng-container *ngIf="coupons.whatsapp != null">
      <i class="fab fa-whatsapp me-2"></i>{{ "coupon.chat_local" | translate }}
    </ng-container>
  </div>
</div>
