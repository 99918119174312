import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CouponShareDialog } from './coupon-share/coupon-share.component';
//import amplitude from 'amplitude-js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {
  @Input() coupons
  @Input() primaryColor
  @Input() secondaryColor
  @Input() softColor
  @Input() landing = null
  @Input() withButtons = true
  @Input() showLocked = false
  @Input() displayModal = true
  @Input() isClient = true
  @Input() shareLink = false
  activated = false;
  constructor(
    private dialog: MatDialog,
    protected $gaService: GoogleAnalyticsService,
    private translate : TranslateService,
    private elem: ElementRef
  ) {}

  ngOnInit(): void {
    let self = this;
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(function(position){
        self.activated = true;
      });
    }
  }

  openWhats(){
    let couponUrl = `${environment.shareUrl}/${environment.countryId}/cupon/${this.coupons.local_id}/${this.coupons.id}/${this.coupons.seotag}/${this.coupons.local_nombre.replaceAll(' ', '-').toLowerCase()}`;
    let msg = `${couponUrl}\n${this.coupons.nombre}\n${this.coupons.local_nombre}\n${this.coupons.descripcion}`;
    let url = `https://api.whatsapp.com/send?text=${encodeURI(msg)}`;
    console.log(url);
    window.open(url, '_blank');
  }
  sendWhatsapp(){
    
   

    if (this.coupons.whatsapp){ 
      this.$gaService.event('Contacto Whatsapp','Cupones',`${this.coupons.id}-${this.coupons.local_id}`,this.coupons.id);
      var message =  this.translate.instant('coupon.share_whatsapp');
     // var message = "Hola vengo de Cuponeala y quiero hacer mi compra con el descuento. Te comparto mi documento una vez que tenga el cupón activo?";
      message = encodeURI(message);
      window.open(`https://api.whatsapp.com/send?phone=${this.coupons.whatsapp}&text=${message}`, '_blank');
    }

  }
  
  openDetailCoupon(isClient: boolean): void {
    /* var amplitudeParams = {coupon: this.coupons.id,  shop:this.coupons.local_id, name: this.coupons.local_nombre, type: this.coupons.nombre, category: this.coupons.categoria_nombre, landing_id : null};
    if (this.landing){
      amplitudeParams.landing_id = this.landing.id;
    }
    amplitude.getInstance().logEvent('Viewing Coupon',amplitudeParams);
    */ 
    if(isClient && this.displayModal){

      // para mostrar fecha antes de activar cupón
      if (this.coupons.hora_desde != null && this.coupons.hora_hasta != null 
        && typeof this.coupons.hora_desde == 'string' && typeof this.coupons.hora_desde == 'string') {
        this.coupons.hora_desde = this.stringToTime(this.coupons.hora_desde)
        this.coupons.hora_hasta = this.stringToTime(this.coupons.hora_hasta) 
      }

      this.dialog.open(CouponShareDialog, {
        panelClass: ['dialog', 'coupon'],
        width: '500px',
        data: {
          landing: this.landing,
          coupons: this.coupons,
          secondaryColor: this.secondaryColor,
          primaryColor: this.primaryColor,
          softColor: this.softColor
        },
      });
    }
  }

  stringToTime(string){
    var timeTokens = string.split(':');
    return new Date(1970,0,1, timeTokens[0], timeTokens[1], timeTokens[2]);
  }

}


