import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBridgeService } from '../api-bridge.service';
@Injectable({
  providedIn: 'root'
})
export class PublicService {
  constructor(
    private apiBridge: ApiBridgeService,
  ) { }

  getCategories(): Observable<any> {
    return this.apiBridge.get(`consumer`,`categories`)
  }

  getCoupons(params: any): Observable<any> {
    return this.apiBridge.get(`consumer`,`coupons`, params)
  }

  getBrands(): Observable<any> {
    return this.apiBridge.get(`consumer`,`brands`)
  }

  contact(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`contact`, data)
  }

  subscribeNewsletter(data: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`subscribe`, data)
  }

  getLanding(seoTag: string, params): Observable<any>{
    return this.apiBridge.get(`consumer`,`landing2/${seoTag}`,params)
  }

  getLandingFilters(landingId: string): Observable<any>{
    return this.apiBridge.get(`consumer`,`landingMeta/${landingId}`)
  }

  filterLandingCoupons(seoTag:string, data: any): Observable<any>{
    return this.apiBridge.post(`consumer`,`filterLandingCoupons/${seoTag}`, data);
  }

  getEncuestaData(params: any): Observable<any>{
    return this.apiBridge.get(`consumer`,`surveyContent`, params)
  }

  getShop(params: any): Observable<any>{
    return this.apiBridge.get(`consumer`,`shops`, params)
  }

  getCouponAvailableShops(params: any): Observable<any>{
    return this.apiBridge.get(`consumer`,`couponAvailableShops`, params)
  }

  getProvince(): Observable<any> {
    return this.apiBridge.get(`consumer`,`provinces`)
  }
  getLocalNeighbourhoods(): Observable<any> {
    return this.apiBridge.get(`consumer`,`neighbourhoods`)
  }

  activateCoupon(params: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`activate`, params)
  }
  activateCouponLogged(params: any): Observable<any> {
    return this.apiBridge.post(`consumer`,`auth/activate`, params)
  }
  getClientBySeotag(seotag: string): Observable<any> {
    return this.apiBridge.get(`consumer`,`client/${seotag}`)
  }
  getClientLanding(id: string): Observable<any> {
    return this.apiBridge.get(`consumer`,`clientLanding/${id}`)
  }
  //Filters
  getNeighbourhoods(): Observable<any> {
    return this.apiBridge.get(`consumer`,`activeNeighbourhoods`)
  }
  getDiscounts(): Observable<any> {
    return this.apiBridge.get(`consumer`,`discounts`)
  }

  getUserByDocument(params: any): Observable<any>{
    return this.apiBridge.get(`consumer`,`user`, params)
  }
}